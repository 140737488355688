import { Spinner } from "@shopify/polaris";

const CenteredSpinner = () => (
  <div
    style={{
      justifyContent: "center",
      display: "flex",
    }}
  >
    <Spinner />
  </div>
);

export default CenteredSpinner;

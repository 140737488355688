import { gql } from "apollo-boost";
import createApp from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { shopifyConfig } from "../App";

export const getShopMetadata = gql`
  query {
    shop {
      myshopifyDomain
    }
  }
`;

export const authenticateAndLogin = (shop) => {
  let shopDomain = shop;
  if (!shopDomain) {
    const urlParams = new URLSearchParams(window.location.search);
    shopDomain = urlParams.get("shop");
  }

  if (shopDomain) {
    window.open(
      `https://${process.env.REACT_APP_PS_APP_URL}/shopify/init?shop=${shopDomain}`,
      "_blank"
    );
  } else {
    window.open(`https://${process.env.REACT_APP_PS_APP_URL}/login`, "_blank");
  }
};

export const redirectToOauthFlow = async (redirectToEmbedded = true) => {
  const params = new URLSearchParams(window.location.search);
  const embedded = params.get("embedded");
  let route = `${process.env.REACT_APP_POSTSCRIPT_BASE_URL}/shop/init?shop=${shopifyConfig.shop}`;

  if (redirectToEmbedded) {
    route = `${route}&embedded=${true}`;
  }

  const response = await fetch(route);
  const { permission_url: permissionUrl } = await response.json();

  if (embedded) {
    const app = createApp(shopifyConfig);
    const redirect = Redirect.create(app);
    redirect.dispatch(Redirect.Action.REMOTE, permissionUrl);
    return;
  }

  await new Promise((resolve) => {
    window.location.assign(permissionUrl);

    // Wait an absurdly long time to ensure the user is redirected before we resolve in case the user has a slow connection
    // eslint-disable-next-line no-promise-executor-return
    return setTimeout(resolve, 5000);
  });
};

import React from "react";
import ReactDOM from "react-dom/client";
import * as FullStory from "@fullstory/browser";
import "./index.css";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
  minIdLength: 1,
});

FullStory.init({
  orgId: "o-1EAA6K-na1",
  namespace: "FS",
  recordOnlyThisIFrame: true,
  recordCrossDomainIFrames: true,
});

Sentry.init({
  dsn: "https://936410bc277f3bab3da383389db1e919@o142330.ingest.sentry.io/4506707665485824",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  networkDetailAllowUrls: [/^https:\/\/internal-api\.postscript\.io\/.*$/],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

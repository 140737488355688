import React, { useEffect } from "react";
import { useNavigate } from "@shopify/app-bridge-react";
import * as FullStory from "@fullstory/browser";
import CenteredSpinner from "./components/CenteredSpinner";

const Auth = () => {
  const navigate = useNavigate();
  const { search } = window.location;
  const params = new URLSearchParams(search);

  useEffect(() => {
    (async () => {
      const shop = params.get("shop");
      const host = params.get("host");

      FullStory.identify(shop, {
        shopifyDomain: shop,
        SCAVersion: 2,
      });

      /*
       The try/catch here is a hacky workaround until we figure out why 
       the Auth component renders multiple times during the installation 
       flow.  Since it renders multiple times, /auth/shop is hit multiple 
       times but only  one includes a valid hmac.  If we don't catch the 
       exception it hangs the app.

       This can be removed when the Auth component is only rendering once 
       and making one API call.
      */
      try {
        const results = await fetch(
          `${process.env.REACT_APP_POSTSCRIPT_BASE_URL}/auth/shop`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({params: window.location.search})
          }
        ).then((response) => response.json());

        if (!results || !results.access_token) {
          const errorMessage =
            "Error authenticating with Shopify. Please contact support if this error persists.";
          throw new Error(errorMessage);
        }

        localStorage.setItem("access_token", results.access_token);
        localStorage.setItem("refresh_token", results.refresh_token);
      } catch (exception) {
        console.error(exception);
      }

      navigate(`/?host=${host}&shop=${shop}`);
    })();
  }, []);

  return <CenteredSpinner />;
};

export default Auth;

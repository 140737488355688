import { useContext } from "react";
import { Modal, TextContainer } from "@shopify/polaris";
import AppContext from "./AppContext";
import { emitAmplitudeEvent, AMPLITUDE_EVENTS } from "../utils/amplitude";
import PostscriptApiClient from "../utils/psClient";

const DisconnectModal = ({
  isVisible,
  setIsVisible,
  setIsAccountConnected,
}) => {
  const { userId, shopId } = useContext(AppContext);
  return (
    <Modal
      open={isVisible}
      onClose={() => setIsVisible(false)}
      title="Disconnect Postscript account?"
      primaryAction={{
        content: "Cancel",
        onAction: () => setIsVisible(false),
        outline: true,
      }}
      secondaryActions={[
        {
          content: "Disconnect",
          onAction: async () => {
            const client = new PostscriptApiClient(shopId);
            await client.put("/v2/sales-channel/account", {
              active_account: false,
            });
            setIsAccountConnected(false);
            emitAmplitudeEvent(
              AMPLITUDE_EVENTS.SALES_CHANNEL_APP_DISCONNECTED,
              userId,
              shopId
            );
          },
          destructive: true,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            Disconnecting your Postscript account will remove your products from
            Postscript. You can reconnect your account at anytime to continue
            selling on Postscript.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default DisconnectModal;

import ProductDetail from "./ProductDetail";

const CartDetail = ({ currentVariantId, cart }) => {
  if (currentVariantId) {
    return <ProductDetail cart={cart} currentVariantId={currentVariantId} />;
  }

  return <p>No products in this cart</p>;
};

export default CartDetail;

import { Layout, Page } from "@shopify/polaris";
import { useState, useEffect } from "react";
import * as FullStory from "@fullstory/browser";

import PostscriptPay from "./components/PostscriptPay";
import Account from "./components/Account";
import NewAccount from "./components/NewAccount";
import PostscriptApiClient from "./utils/psClient";
import AppContext from "./components/AppContext";
import Footer from "./components/Footer";
import CenteredSpinner from "./components/CenteredSpinner";

const Home = ({ hmacRequestComplete }) => {
  const [completedOnboarding, setCompletedOnboarding] = useState(false);
  const [psPayFFEnabled, setPsPayFFEnabled] = useState(false);
  const [userId, setUserId] = useState();
  const [shopId, setShopId] = useState();
  const [shop, setShop] = useState();
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const userSettings = {
    userId,
    shopId,
    shop,
  };

  useEffect(() => {
    if (!hmacRequestComplete) return;

    (async () => {
      setLoading(true);
      try {
        const params = new URLSearchParams(window.location.search);
        const shop = params.get("shop");

        if (shop) {
          setShop(shop);
        }

        FullStory.identify(shop, {
          shopifyDomain: shop,
          SCAVersion: 2,
        });

        const client = new PostscriptApiClient(shop);

        const accountResult = await client.get("/v2/sales-channel/account");

        if (!accountResult) {
          return;
        }

        if (accountResult.completed_onboarding) {
          setCompletedOnboarding(true);
        }
        if (accountResult.pspay_enabled) {
          setPsPayFFEnabled(true);
        }
        if (accountResult.user_id) {
          setUserId(accountResult.user_id);
        }
        if (accountResult.shop_id) {
          setShopId(accountResult.shop_id);

          FullStory.setUserVars({
            shopId: accountResult.shop_id,
          });
        }
      } catch (err) {
        // If we threw an unhandled error here it would prevent the user from logging in, instead we log and continue
        console.log(err);
      }

      setLoading(false);
    })();
  }, [hmacRequestComplete]);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <AppContext.Provider value={userSettings}>
      <Page>
        <Layout>
          {completedOnboarding ? <Account /> : <NewAccount />}
          {psPayFFEnabled && <PostscriptPay />}
        </Layout>
      </Page>
      <Footer />
    </AppContext.Provider>
  );
};

export default Home;

import { useContext, useEffect, useState } from "react";
import { Layout, Banner } from "@shopify/polaris";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { useAppBridge } from "@shopify/app-bridge-react";
import PostscriptPayDemo from "./PostscriptPayDemo";
import RequirementsChecklist from "./RequirementsChecklist";
import TermsAndConditions from "./TermsAndConditions";
import PostscriptApiClient from "../utils/psClient";
import AppContext from "./AppContext";
// import CenteredSpinner from "./CenteredSpinner";

const getShopCheckoutAPIStatus = gql`
  query {
    shop {
      checkoutApiSupported
    }
  }
`;

const PostscriptPay = () => {
  const app = useAppBridge();
  const { shopId } = useContext(AppContext);
  const [psPayEnabled, setPsPayEnabled] = useState(false);
  const [isAccountConnected, setIsAccountConnected] = useState(false);
  const [appLoading, setAppLoading] = useState(false);
  const { loading, data } = useQuery(getShopCheckoutAPIStatus);
  const hasMetRequirements =
    !appLoading &&
    !loading &&
    data?.shop?.checkoutApiSupported &&
    isAccountConnected;

  useEffect(() => {
    (async () => {
      setAppLoading(true);

      const client = new PostscriptApiClient(shopId);
      const accountResult = await client.get("/v2/sales-channel/account");

      if (accountResult.pspay_enabled) {
        setPsPayEnabled(true);
      }
      if (!accountResult.active_account) {
        setIsAccountConnected(false);
        setAppLoading(false);
        return;
      }
      setIsAccountConnected(true);
      setAppLoading(false);
    })();
  }, [setIsAccountConnected, setPsPayEnabled, app]);

  if (appLoading || loading) {
    return;
  }

  return (
    <>
      {!psPayEnabled && (
        <Layout.Section>
          <Banner status="warning">
            <h2>
              <b>Text-to-Buy</b>
            </h2>
            <p>
              Text-to-Buy is currently in closed beta. Stay tuned for future
              updates.
            </p>
          </Banner>
        </Layout.Section>
      )}
      <Layout.Section>
        {!psPayEnabled && (
          <div
            className="Polaris-Card"
            style={{
              position: "absolute",
              width: "933px",
              height: "414px",
              marginTop: "16px",
              backgroundColor:
                "rgba(251,250,250,0.4)" /* Light gray background with opacity */,
              zIndex: 2,
            }}
          />
        )}
        {hasMetRequirements && (
          <PostscriptPayDemo setIsAccountConnected={setIsAccountConnected} />
        )}
        {!hasMetRequirements && (
          <RequirementsChecklist
            props={{
              isShopPaySetup: data?.shop?.checkoutApiSupported,
              setIsAccountConnected,
              isAccountConnected,
            }}
          />
        )}
      </Layout.Section>

      <hr
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          borderTop: "1px solid #DDD",
        }}
      />
      <TermsAndConditions />
    </>
  );
};

export default PostscriptPay;

import { useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import { Redirect } from "@shopify/app-bridge/actions";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { postscriptUrl } from "./utils/psClient";
import { redirectToOauthFlow } from "./utils/navUtils";

const pspayCustomFetchWrapper = (_uri, options) => {
  const params = new URLSearchParams(window.location.search);
  const shop = params.get("shop");

  const accessToken = localStorage.getItem("access_token");

  // Missing access token indicates that re-authentication is required
  if (!accessToken) {
    return redirectToOauthFlow();
  }

  const optionsWithHeaders = {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-Shopify-Shop-Domain": shop,
    },
  };

  return fetch(`${postscriptUrl}/v2/sales-channel/graphql`, optionsWithHeaders);
};

const userLoggedInFetch = (app) => {
  const fetchFunction = authenticatedFetch(app, pspayCustomFetchWrapper);

  return async (uri, options) => {
    const response = await fetchFunction(uri, options);

    if (
      response.headers.get("X-Shopify-API-Request-Failure-Reauthorize") === "1"
    ) {
      const authUrlHeader = response.headers.get(
        "X-Shopify-API-Request-Failure-Reauthorize-Url"
      );

      const redirect = Redirect.create(app);

      // TODO: use navigate instead of redirect dispatch?
      redirect.dispatch(Redirect.Action.APP, authUrlHeader || `/auth`);
      return null;
    }

    return response;
  };
};

const ApolloWrapper = ({ children }) => {
  const app = useAppBridge();
  const client = new ApolloClient({
    fetch: userLoggedInFetch(app),
    fetchOptions: {
      credentials: "include",
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;

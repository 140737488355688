import { Icon } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";

const NOT_COMPLETE_STATUS =
  "<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M14.786 3.377a.983.983 0 01-.53-.154A8 8 0 0011.779 2.2a1 1 0 11.379-1.964l.063.014a9.93 9.93 0 013.1 1.28c.468.293.61.91.315 1.378a.994.994 0 01-.847.468h-.004l.001.001zM1 8.967a1 1 0 001.2-.751 7.998 7.998 0 011.028-2.48 1.003 1.003 0 10-1.701-1.064 10.106 10.106 0 00-1.28 3.093v.004A1.002 1.002 0 001 8.966zm17.78.051a.999.999 0 01-.973-.78 8.054 8.054 0 00-1.023-2.478 1.014 1.014 0 01.31-1.39.993.993 0 011.37.298v.003l.022.035c.594.953 1.024 2 1.27 3.095a1 1 0 01-.75 1.2h-.003c-.073.014-.148.02-.222.018h-.001zM1.528 15.312c.18.29.498.465.84.465h.001a1 1 0 00.848-1.537 7.976 7.976 0 01-1.02-2.466 1 1 0 00-1.95.443 9.948 9.948 0 001.263 3.067l.018.028zm16.084.493a1.002 1.002 0 01-.845-1.533c.48-.76.83-1.596 1.033-2.472a1 1 0 111.95.45 10.058 10.058 0 01-1.286 3.09.999.999 0 01-.852.465zm-9.851 3.942c.075.017.149.025.225.025h.002v.001a.999.999 0 00.223-1.974 7.999 7.999 0 01-2.475-1.03 1 1 0 00-1.065 1.693 9.997 9.997 0 003.09 1.285zm3.227-.967a1 1 0 001.222.976 9.973 9.973 0 003.083-1.272 1 1 0 10-1.055-1.7 7.935 7.935 0 01-2.47 1.02.997.997 0 00-.78.976zM4.381 2.916c.186.284.503.456.843.454h.001c.189 0 .376-.053.536-.153A8.027 8.027 0 018.221 2.2 1 1 0 107.776.25a9.717 9.717 0 00-2.992 1.224.995.995 0 00-.403 1.443z' /></svg>";

const StatusIcon = ({ isSuccessful, label }) => {
  const source = isSuccessful ? CircleTickMajor : NOT_COMPLETE_STATUS;
  const color = isSuccessful ? "success" : "critical";

  return (
    <>
      <div style={{ display: "inline-block", innerHeight: "50px" }}>
        <Icon source={source} color={color} />
      </div>

      <div
        style={{
          display: "inline-block",
          position: "absolute",
          marginLeft: "10px",
        }}
      >
        <p>{label}</p>
      </div>
    </>
  );
};

export default StatusIcon;

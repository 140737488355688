import PostscriptApiClient from "./psClient";

export const deleteAllCartProducts = async (shop, cartId) => {
  /*
    @param cartId: int - ID of the cart to update
    @returns void

    Deletes all products from the specified cart
  */
  const client = new PostscriptApiClient(shop);

  const cartProducts = await client.get(`/v2/pspay/carts/${cartId}/products`, {
    params: {
      cartId,
    },
  });

  const promises = [];
  cartProducts.products.forEach((product) => {
    promises.push(
      client.delete(`/v2/pspay/carts/${cartId}/products/${product.id}`, {
        params: {
          cartId,
          productId: product.id,
        },
      })
    );
  });

  await Promise.all(promises);
};

export const generateBuyTagFromSlug = (slug) =>
  /*
    @param slug: string - PS Pay slug for buytag
    @returns string

    Creates a buytag from a slug and returns it
  */
  `#BUY${slug
    .toUpperCase()
    .replace(/[^\w\s]|_/g, "")
    .replace(/\s+/g, "")}`;

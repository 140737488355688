import { ResourcePicker } from "@shopify/app-bridge-react";
import { deleteAllCartProducts } from "../utils/cartUtils";
import PostscriptApiClient from "../utils/psClient";

const ProductPicker = ({
  isVisible,
  setIsVisible,
  setCurrentVariantId,
  cartId,
}) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const shop = params.get("shop");

  const updateCart = async (product) => {
    const tokens = product.id.split("/");
    const variantId = tokens[tokens.length - 1];

    await deleteAllCartProducts(shop, cartId);

    const formattedCartId = parseInt(cartId, 10);

    const client = new PostscriptApiClient(shop);
    await client.post(`/v2/pspay/carts/${formattedCartId}/products`, {
      cart_id: formattedCartId,
      variant_id: parseInt(variantId, 10),
      price: parseInt(product.price.replace(".", ""), 10),
      taxable: product.taxable,
    });
  };

  return (
    <ResourcePicker
      resourceType="Product"
      selectMultiple={false}
      showVariants
      open={isVisible}
      onSelection={(resources) => {
        const selection = resources.selection[0].variants[0];

        const tokens = selection.id.split("/");
        const variantId = tokens[tokens.length - 1];

        setCurrentVariantId(variantId);
        updateCart(selection);
        setIsVisible(false);
      }}
      onCancel={() => setIsVisible(false)}
    />
  );
};

export default ProductPicker;

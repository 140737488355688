import { Button, Card, Heading, Badge } from "@shopify/polaris";
import { useEffect, useState, useContext } from "react";
import CartOverview from "./CartOverview";
import DisconnectModal from "./DisconnectModal";
import AppContext from "./AppContext";
import {
  emitAmplitudeEvent,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PRODUCT_AREA,
} from "../utils/amplitude";
import PostscriptApiClient from "../utils/psClient";
import { authenticateAndLogin } from "../utils/navUtils";
import CenteredSpinner from "./CenteredSpinner";

const GO_TO_POSTSCRIPT_PAY = "Go To Text-to-Buy";

const SCA_DEFAULT_CART_SLUG_PREFIX = "CART";

// This function takes the shop name, removes .myshopify.com, capitalizes,
// strips punctuation, and adds the prefix to generate the default SCA slug
export const buildDefaultSlug = (shop) =>
  `${SCA_DEFAULT_CART_SLUG_PREFIX}${shop
    .replace(".myshopify.com", "")
    .toUpperCase()
    .replace(/[^\w\s]|_/g, "")
    .replace(/\s+/g, "")}`;

const PostscriptPayDemo = ({ setIsAccountConnected }) => {
  const { userId, shopId, shop } = useContext(AppContext);
  const [isDisconnectModalVisible, setIsDisconnectModalVisibile] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState();
  const [currentVariantId, setCurrentVariantId] = useState();

  const handleClick = () => {
    authenticateAndLogin(shop);

    emitAmplitudeEvent(AMPLITUDE_EVENTS.BUTTON_CLICKED, userId, shopId, {
      product_area: AMPLITUDE_PRODUCT_AREA,
      text: GO_TO_POSTSCRIPT_PAY,
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const client = new PostscriptApiClient(shop);
      const cartResult = await client.get("/v2/pspay/carts");

      const defaultSlug = buildDefaultSlug(shop);

      let cart = cartResult.carts.find((cart) => cart.slug === defaultSlug);

      if (!cart) {
        cart = await client.post("/v2/pspay/carts", {
          name: `${shop} SCA_DEFAULT_CART`,
          description: "Default cart for the SCA",
          slug: defaultSlug,
        });
        cartResult.carts.push(cart);
      }

      if (cart.status === "no products") {
        setCartData(cartResult);

        setIsLoading(false);
        return;
      }

      const cartProducts = await client.get(
        `/v2/pspay/carts/${cart.id}/products`,
        {
          params: {
            cartId: cart.id,
          },
        }
      );

      const currentProduct = cartProducts.products[0];

      setCurrentVariantId(currentProduct.variant_id);

      setCartData(cartResult);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Card>
      <Card.Header title="Text-to-Buy">
        <Badge status="success">Active</Badge>
      </Card.Header>
      <section style={{ margin: "1rem 2rem 2rem" }}>
        <p style={{ padding: "2rem 0" }}>
          Text-to-Buy allows your customers to purchase from you via SMS.
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleClick} primary>
            {GO_TO_POSTSCRIPT_PAY}
          </Button>
        </div>
      </section>
      <hr style={{ borderTop: "1px solid #DDD" }} />
      <section style={{ margin: "1rem 2rem 2rem" }}>
        <Heading>Published Product</Heading>
      </section>
      <CartOverview
        cartData={cartData}
        setCurrentVariantId={setCurrentVariantId}
        currentVariantId={currentVariantId}
      />
      <Card.Section
        title="Disconnect Text-to-Buy"
        actions={[
          {
            content: "Disconnect Postscript",
            destructive: true,
            onAction: () => setIsDisconnectModalVisibile(true),
          },
        ]}
      >
        Disconnecting will disable Text-to-Buy functionality.
      </Card.Section>
      <DisconnectModal
        isVisible={isDisconnectModalVisible}
        setIsVisible={setIsDisconnectModalVisibile}
        setIsAccountConnected={setIsAccountConnected}
      />
    </Card>
  );
};

export default PostscriptPayDemo;

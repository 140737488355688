import React, { useMemo, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { toast, ToastContainer, Slide } from "react-toastify";
import jwtDecode from "jwt-decode";
import { Provider } from "@shopify/app-bridge-react";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import "react-toastify/dist/ReactToastify.css";
import ApolloWrapper from "ApolloWrapper";
import Auth from "./Auth";
import "./App.css";
import Home from "./Home";
import CenteredSpinner from "./components/CenteredSpinner";
import { redirectToOauthFlow } from "./utils/navUtils";
import PostscriptApiClient from "./utils/psClient";

const BLOCKED_SITE_DATA_ERROR = "Failed to read the 'localStorage' property";
const { search } = window.location;
const params = new URLSearchParams(search);
const host = params.get("host");
const shop = params.get("shop");
export const shopifyConfig = {
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  host,
  shop,
};

const App = ({ hmacRequestComplete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path) => navigate(path, { replace: true }) }),
    [navigate]
  );

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  return (
    <AppProvider i18n={translations}>
      <Provider config={shopifyConfig} router={router}>
        <ApolloWrapper>
          <Routes>
            <Route
              exact
              path="/"
              element={<Home hmacRequestComplete={hmacRequestComplete} />}
            />
            <Route exact path="/auth" element={<Auth />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer
            hideProgressBar
            limit={5}
            position="bottom-center"
            theme="light"
            transition={Slide}
          />
        </ApolloWrapper>
      </Provider>
    </AppProvider>
  );
};

const AppWrapper = () => {
  const params = new URLSearchParams(window.location.search);
  const shop = params.get("shop");
  const [loading, setLoading] = useState(true);
  const [hmacRequestComplete, setHMACRequestComplete] = useState(false);

  if (!shop) {
    // TODO: Replace with something better (like a login screen, or just some text instructions)
    return;
  }
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const client = new PostscriptApiClient(shop);
        const accountResult = await client.get(
          "/v2/sales-channel/account/hmac",
          false
        );

        if (accountResult.status === 404 || accountResult.uninstalled) {
          await redirectToOauthFlow(false);
          return;
        }

        setHMACRequestComplete(true);

        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
          const decoded = jwtDecode(accessToken);

          // Check if token is for another shop
          if (shop !== decoded.username) {
            localStorage.removeItem("access_token");
          }
        }
      } catch (exception) {
        if (exception.toString().includes(BLOCKED_SITE_DATA_ERROR)) {
          toast.error(
            "3rd party data and cookies are currently blocked. To get full access to the Postscript Sales Channel App, please enable them in your browser settings."
          );
        } else {
          throw exception;
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <BrowserRouter>
      <App hmacRequestComplete={hmacRequestComplete} />
    </BrowserRouter>
  );
};

export default AppWrapper;

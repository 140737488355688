import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import CenteredSpinner from "./CenteredSpinner";

const shopifyDefaultImage =
  "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";

const altQuery = gql`
  query ($id: ID!) {
    product(id: $id) {
      featuredImage {
        src
      }
    }
  }
`;

const Thumbnail = ({ variantImageSrc, productId }) => {
  const { loading, data } = useQuery(altQuery, {
    variables: { id: productId },
  });

  if (loading || !data) {
    return <CenteredSpinner />;
  }

  return (
    <div style={{ width: 60, height: 60 }}>
      <img
        alt="Product"
        style={{
          border: "1px solid #C9CCCF",
          borderRadius: 6,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        src={
          variantImageSrc ||
          data.product.featuredImage?.src ||
          shopifyDefaultImage
        }
      />
    </div>
  );
};

export default Thumbnail;

import { track, setGroup, setUserId } from "@amplitude/analytics-browser";

export const AMPLITUDE_EVENTS = {
  LINK_CLICKED: "link clicked",
  BUTTON_CLICKED: "button clicked",
  SALES_CHANNEL_APP_CONNECTED: "sales channel app connected",
  SALES_CHANNEL_APP_DISCONNECTED: "sales channel app disconnected",
};

export const AMPLITUDE_PRODUCT_AREA = "sales channel app";

export function emitAmplitudeEvent(
  event,
  userId,
  shopId,
  eventProperties = {}
) {
  if (!userId || !shopId) return;
  try {
    setUserId(userId.toString());
    setGroup("shop_id", shopId);
    track(event, eventProperties);
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
}

import { useContext } from "react";
import { Link, FooterHelp } from "@shopify/polaris";
import AppContext from "./AppContext";
import {
  emitAmplitudeEvent,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PRODUCT_AREA,
} from "../utils/amplitude";

export const URL = "https://help.postscript.io/";

const Footer = () => {
  const appContext = useContext(AppContext);
  return (
    <FooterHelp>
      Learn more about{" "}
      <Link
        external
        url={URL}
        onClick={() => {
          emitAmplitudeEvent(
            AMPLITUDE_EVENTS.LINK_CLICKED,
            appContext.userId,
            appContext.shopId,
            {
              product_area: AMPLITUDE_PRODUCT_AREA,
              href: URL,
            }
          );
        }}
      >
        Postscript
      </Link>
    </FooterHelp>
  );
};

export default Footer;

/* eslint-disable no-underscore-dangle */
import { api } from "./apiClient";
import { redirectToOauthFlow } from "./navUtils";

const CryptoJS = require("crypto-js");

export const secret = process.env.REACT_APP_SALES_CHANNEL_SECRET;
export const postscriptUrl = process.env.REACT_APP_POSTSCRIPT_API_URL;

export const toHmac = (data) =>
  CryptoJS.HmacSHA256(data, secret).toString(CryptoJS.enc.Hex);

class PostscriptApiClient {
  constructor(shopName, errorReference = "") {
    this.shopName = shopName;
    this.errorRef = errorReference;
  }

  get(route, jwtAuthentication = true) {
    const options = {
      method: "GET",
    };
    return this._request(route, options, jwtAuthentication);
  }

  post(route, body) {
    const options = {
      method: "POST",
      body,
    };
    return this._request(route, options);
  }

  put(route, body) {
    const options = {
      method: "PUT",
      body,
    };
    return this._request(route, options);
  }

  delete(route) {
    const options = {
      method: "DELETE",
    };
    return this._request(route, options);
  }

  async _request(route, options, jwtAuthentication = true) {
    let authorization;
    if (jwtAuthentication) {
      // TODO: This can be improved by passing in an access token on instantiation.
      const accessToken = localStorage.getItem("access_token");

      // Missing access token indicates that re-authentication is required
      if (!accessToken) {
        return redirectToOauthFlow();
      }
      authorization = {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      authorization = {
        hmac: toHmac(`${secret}${options.body || ""}${this.shopName}`),
      };
    }

    const headers = {
      ...authorization,
      "Content-Type": "application/json",
      "X-Shopify-Shop-Domain": this.shopName,
    };

    const optionsWithHeaders = {
      ...options,
      headers,
    };

    const url = `${postscriptUrl}${route}`;
    console.debug(`PSPAY CLIENT REQ: ${url}`, optionsWithHeaders);

    if (jwtAuthentication) {
      return api[options.method.toLowerCase()](url, optionsWithHeaders);
    }

    /*
      We only execute the below code when fetching account info
      If it 404s, that means the shop does not exist and needs to install
    */
    const data = await fetch(url, optionsWithHeaders);

    const decodedData = await data.json();

    return {
      status: data.status,
      ...decodedData,
    };
  }
}

export default PostscriptApiClient;

import { Card, Button, Banner, Badge, Link } from "@shopify/polaris";
import { useState, useContext } from "react";
import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge } from "@shopify/app-bridge-react";
import StatusIcon from "./StatusIcon";
import AppContext from "./AppContext";
import { emitAmplitudeEvent, AMPLITUDE_EVENTS } from "../utils/amplitude";
import PostscriptApiClient from "../utils/psClient";

const ACTIVATE_SHOP_PAY = "Activate Shop Pay";

const RequirementsChecklist = ({
  props: { isAccountConnected, isShopPaySetup, setIsAccountConnected },
}) => {
  const app = useAppBridge();
  const { userId, shopId } = useContext(AppContext);
  const [isConnectLoading, setIsConnectLoading] = useState(false);
  const [showPsPayError, setShowPsPayError] = useState(false);

  const handleConnectClick = async () => {
    setIsConnectLoading(true);

    const client = new PostscriptApiClient(shopId);

    const activateResult = await client.put("/v2/sales-channel/account", {
      active_account: true,
    });

    setIsConnectLoading(false);

    emitAmplitudeEvent(
      AMPLITUDE_EVENTS.SALES_CHANNEL_APP_CONNECTED,
      userId,
      shopId
    );

    if (activateResult.active_account) {
      // This call triggers the rerender
      setIsAccountConnected(true);
      setShowPsPayError(false);
      return;
    }
    setShowPsPayError(true);
  };

  const handleShopPayClick = () => {
    const redirect = Redirect.create(app);

    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: "/settings/payments",
      newContext: true,
    });
  };

  return (
    <Card>
      <Card.Header title="Text-to-Buy">
        <Badge status="critical">Inactive</Badge>
      </Card.Header>
      <section style={{ margin: "1rem 2rem 2rem" }}>
        <p style={{ padding: "2rem 0" }}>
          Text-to-Buy allows your customers to purchase from you via SMS.
        </p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={isAccountConnected}
            onClick={handleConnectClick}
            loading={isConnectLoading}
            primary
          >
            Connect
          </Button>
        </div>
      </section>
      {showPsPayError && (
        <section style={{ margin: "2rem", paddingBottom: "2rem" }}>
          <Banner
            title="Activation Failed"
            status="warning"
            onDismiss={() => {
              setShowPsPayError(false);
            }}
          >
            <p>
              Activation was not successful, ensure that PS Pay has been enabled
              in Postscript
            </p>
          </Banner>
        </section>
      )}
      <hr style={{ marginTop: 0, borderTop: "1px solid #DDD" }} />
      <section style={{ margin: "2rem", paddingBottom: "2rem" }}>
        <Banner status="warning">
          <p>Setup Text-to-Buy by completing the steps below.</p>
        </Banner>
        <section style={{ margin: "2rem", paddingBottom: "2rem" }}>
          <StatusIcon
            isSuccessful={isAccountConnected}
            label="Connect your Postscript account"
          />
        </section>
        <section style={{ margin: "2rem", paddingBottom: "2rem" }}>
          <StatusIcon
            isSuccessful={isShopPaySetup}
            label={
              <Link onClick={handleShopPayClick}>{ACTIVATE_SHOP_PAY}</Link>
            }
          />
        </section>
      </section>
    </Card>
  );
};

export default RequirementsChecklist;

import { Button } from "@shopify/polaris";
import { useState } from "react";
import ProductPicker from "./ProductPicker";
import CartDetail from "./CartDetail";
import CenteredSpinner from "./CenteredSpinner";

const CartOverview = ({ cartData, setCurrentVariantId, currentVariantId }) => {
  const [isProductPickerVisible, setIsProductPickerVisible] = useState(false);

  if (!cartData) {
    return <CenteredSpinner />;
  }

  const cart = cartData.carts[0];

  return (
    <div
      style={{
        backgroundColor: "#FAFBFB",
        padding: "2rem",
        margin: "2rem 0 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <CartDetail cart={cart} currentVariantId={currentVariantId} />
      <div style={{ marginLeft: "auto" }}>
        <Button plain onClick={() => setIsProductPickerVisible(true)}>
          Change Product
        </Button>
      </div>
      <ProductPicker
        isVisible={isProductPickerVisible}
        setIsVisible={setIsProductPickerVisible}
        setCurrentVariantId={setCurrentVariantId}
        cartId={cart.id}
      />
    </div>
  );
};

export default CartOverview;

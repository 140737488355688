import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { generateBuyTagFromSlug } from "../utils/cartUtils";
import Thumbnail from "./Thumbnail";
import CenteredSpinner from "./CenteredSpinner";

const getCurrentProductInfo = gql`
  query ($id: ID!) {
    productVariant(id: $id) {
      image {
        src
      }
      displayName
      product {
        id
      }
    }
  }
`;

const ProductDetail = ({ cart, currentVariantId }) => {
  const graphQLId = `gid://shopify/ProductVariant/${currentVariantId}`;
  const { loading, data } = useQuery(getCurrentProductInfo, {
    variables: { id: graphQLId },
  });

  if (loading || !data) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <Thumbnail
        variantImageSrc={data.productVariant.image?.src}
        productId={data.productVariant.product.id}
      />
      <h2 style={{ margin: "0 4rem", fontWeight: "bold" }}>
        {data.productVariant.displayName.replace(" - Default Title", "")}
      </h2>
      <p>{generateBuyTagFromSlug(cart.slug)}</p>
    </>
  );
};

export default ProductDetail;

import { useContext } from "react";
import { useQuery } from "react-apollo";
import { Card, Layout } from "@shopify/polaris";

import AppContext from "./AppContext";
import CenteredSpinner from "./CenteredSpinner";
import { authenticateAndLogin, getShopMetadata } from "../utils/navUtils";
import {
  emitAmplitudeEvent,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PRODUCT_AREA,
} from "../utils/amplitude";

const goToDashboardText = "Go to your Postscript Dashboard";

const accountTitle = "Postscript Account";

const accountBody =
  "Log in to Postscript to view recent SMS performance and craft new conversations.";

const Account = () => {
  const appContext = useContext(AppContext);
  const { loading, data } = useQuery(getShopMetadata);

  const handleRedirect = async () => {
    authenticateAndLogin(data?.shop?.myshopifyDomain);
    emitAmplitudeEvent(
      AMPLITUDE_EVENTS.BUTTON_CLICKED,
      appContext.userId,
      appContext.shopId,
      {
        product_area: AMPLITUDE_PRODUCT_AREA,
        text: goToDashboardText,
      }
    );
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Layout.Section>
      <Card
        title={accountTitle}
        primaryFooterAction={{
          content: goToDashboardText,
          onAction: () => handleRedirect(),
        }}
      >
        <p style={{ margin: "2rem", marginBottom: "3rem" }}>{accountBody}</p>
      </Card>
      <hr
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          borderTop: "1px solid #DDD",
        }}
      />
    </Layout.Section>
  );
};

export default Account;

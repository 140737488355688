import { Card, Layout, Link } from "@shopify/polaris";

const TermsAndConditions = () => (
  <Layout.Section>
    <Card title="Terms & Conditions">
      <p style={{ padding: "3rem" }}>
        By using Postscript, you agree to Postscript&apos;s{" "}
        <Link external url="https://postscript.io/terms-of-service">
          Terms of Service
        </Link>{" "}
        and Postscript&apos;s{" "}
        <Link external url="https://postscript.io/privacy">
          Privacy Policy
        </Link>
        .
      </p>
    </Card>
  </Layout.Section>
);

export default TermsAndConditions;

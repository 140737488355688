import { useContext } from "react";
import { useQuery } from "react-apollo";
import { Card, Layout, Button } from "@shopify/polaris";
import { authenticateAndLogin, getShopMetadata } from "../utils/navUtils";
import CenteredSpinner from "./CenteredSpinner";
import AppContext from "./AppContext";
import {
  emitAmplitudeEvent,
  AMPLITUDE_EVENTS,
  AMPLITUDE_PRODUCT_AREA,
} from "../utils/amplitude";

const CREATE_PS_ACCOUNT = "Login or Create your Account";

const NewAccountCard = () => {
  const appContext = useContext(AppContext);
  const { loading, data } = useQuery(getShopMetadata);

  const handleRedirect = async () => {
    authenticateAndLogin(data?.shop?.myshopifyDomain);
    emitAmplitudeEvent(
      AMPLITUDE_EVENTS.BUTTON_CLICKED,
      appContext.userId,
      appContext.shopId,
      {
        product_area: AMPLITUDE_PRODUCT_AREA,
        text: CREATE_PS_ACCOUNT,
      }
    );
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Layout.Section>
      <Card>
        <img
          style={{
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
          width="100%"
          height="100%"
          src="https://i.postimg.cc/QN43Q0q8/cover-image.jpg"
        />
        <div
          style={{
            textAlign: "center",
            margin: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2 style={{ lineHeight: "1.4em", fontSize: 26, color: "#000" }}>
            Build relationships with SMS.
            <br />
            Say hello to your new #1 revenue channel.
          </h2>
          <h3
            style={{
              lineHeight: "1.2em",
              margin: "2rem",
              color: "#6D7175",
              fontSize: 20,
            }}
          >
            Launch your first SMS marketing campaign in minutes! Click the
            button below to create your account and get started.
          </h3>
          <div style={{ margin: "2rem 2rem 4rem" }}>
            <Button primary onClick={() => handleRedirect()}>
              {CREATE_PS_ACCOUNT}
            </Button>
          </div>
        </div>
      </Card>
      <hr
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          borderTop: "1px solid #DDD",
        }}
      />
    </Layout.Section>
  );
};

export default NewAccountCard;

/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from "axios";

const DEFAULT_ERROR_MESSAGE = "Something went wrong.";
const ACCESS_TOKEN_COOKIE_KEY = "access_token";
const REFRESH_TOKEN_COOKIE_KEY = "refresh_token";
const REFRESH_TOKEN_ENDPOINT = "/token/refresh";
const BAD_AUTH_STATUS_CODES = [401, 422];
const LOGOUT_AUTH_STATUS_CODE = 418;
const RETRY_LIMIT = 2;

interface Options {
  body?: any;
  throwErrorAsString?: boolean;
  returnNestedResponseData?: boolean;
}

const DEFAULT_OPTIONS: Options = {
  throwErrorAsString: true,
  returnNestedResponseData: true,
};

// TODO: make sure process.env.REACT_APP_POSTSCRIPT_API_URL is the right url

const client = axios.create({
  baseURL: process.env.REACT_APP_POSTSCRIPT_API_URL,
});

const refreshAccessToken = async () => {
  // TODO: This can be improved by passing in an access token on instantiation.
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_COOKIE_KEY);

  try {
    const {
      data: { access_token: accessToken },
    } = await axios.post(
      `${process.env.REACT_APP_POSTSCRIPT_BASE_URL}${REFRESH_TOKEN_ENDPOINT}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    localStorage.setItem(ACCESS_TOKEN_COOKIE_KEY, accessToken);
  } catch (error: any) {
    if (LOGOUT_AUTH_STATUS_CODE === error.response?.status) {
      // TODO: add re-auth logic
      console.log("Failing to refresh access token. Redirect to login page.");
    }
  }
};

const extractErrorMessage = (error: Error | AxiosError) => {
  if (typeof error === "string") return error;

  if (axios.isAxiosError(error))
    return (
      error.response?.data?.error_message ||
      error.response?.data?.message ||
      error.message ||
      error
    );

  return error?.message || error;
};

client.interceptors.request.use(({ headers, ...config }: any) => {
  // TODO: This can be improved by passing in an access token on instantiation.
  const accessToken = localStorage.getItem(ACCESS_TOKEN_COOKIE_KEY);

  return {
    ...config,
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  };
});

client.interceptors.response.use(
  (data) => {
    if (data.data?.success === false) {
      throw new Error(data.data.error || DEFAULT_ERROR_MESSAGE);
    }

    return data;
  },
  async (error) => {
    const { config } = error;

    config.retries = config.retries || 0;

    // Refresh token and retry
    if (
      BAD_AUTH_STATUS_CODES.includes(error.response?.status) &&
      config.retries < RETRY_LIMIT
    ) {
      config.retries += 1;

      await refreshAccessToken();

      return client(config);
    }

    // Retry a failed get request
    if (config.method === "get" && config.retries < RETRY_LIMIT) {
      config.retries += 1;

      return client(config);
    }

    throw error;
  }
);

export const api = {
  get: async <TResponse = any>(
    url: string,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.get(url);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  post: async <TResponse = any>(
    url: string,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    let body = {};
    if (options && options.body) {
      body = options.body;
    }

    try {
      const response = await client.post(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  put: async <TResponse = any>(
    url: string,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    let body = {};
    if (options && options.body) {
      body = options.body;
    }

    try {
      const response = await client.put(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  patch: async <TResponse = any>(
    url: string,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    let body = {};
    if (options && options.body) {
      body = options.body;
    }

    try {
      const response = await client.patch(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  delete: async <TResponse = any>(
    url: string,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString, body } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.delete(url, { data: body });
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  postFormData: async <TResponse = any>(
    url: string,
    formData: FormData,
    options?: Options
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
};
